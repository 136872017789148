/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import firebase from "firebase/app";
import "firebase/analytics";
import configuration from './src/config/firebase-configuration.json';

firebase.initializeApp(configuration.result.sdkConfig);
firebase.analytics();